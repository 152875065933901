import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SetPasswordContainer, NavMenuContainer, DashboardContainer } from 'containers/UserAccountContainers';

import './styles.scss';

export const AccountPage: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('auth_token');

      if (!token) {
        alert('You must log in to access this page.');
        navigate('/');
        return;
      }

      try {
        const response = await fetch('https://api.medusatest.ru:3443/api/verify-token', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Invalid token');
        }

        const data = await response.json();
        if (data.valid) {
          setUserData(data); // Save user data
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        alert('Session expired. Please log in again.');
        localStorage.removeItem('auth_token');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <section className="account-page">
      <SetPasswordContainer />
      <div className="account-page__content">
        <NavMenuContainer userData={userData} />
        <DashboardContainer userData={userData} />
      </div>
    </section>
  );
};
