import React, { useEffect, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import axios from 'axios';

import { Button, Checkbox, DashboardSectionTitle, Dropdown, RangeSlider, } from 'components';

import { close } from 'assets/images/icons';

import './styles.scss';
import { ModalContext } from 'context/ModalContext';

interface OrderProps {
  toggleForm?: () => void;
}

interface Pricing {
  id: string;
  pricing_id: string;
  term: string;
  period: string;
  price: string;
  currency: string;
}

interface Package {
  id: string;
  name: string;
  pricing: Pricing[];
}

export const Order: React.FC<OrderProps> = ({ toggleForm }) => {
  const { t } = useTranslation('order');
  const [packages, setPackages] = useState<Package[]>([]); // Список пакетов
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null); // Выбранный пакет
  const [selectedPeriod, setSelectedPeriod] = useState<Pricing | null>(null); // Выбранный период

  const [country, setCountry] = useState<string | null>(null);
  const [promocode, setPromocode] = useState('Enter Promocode');
  const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState(0)

// Загрузка пакетов при монтировании компонента
useEffect(() => {
  const fetchPackages = async () => {
    try {
      const response = await axios.get<{ packages: Package[] }>('https://api.medusatest.ru:3443/api/packages/full-list');
      console.log('Packages received:', response.data.packages); // Логируем данные
      setPackages(response.data.packages);
    } catch (error) {
      console.error('Error fetching packages:', error);
    }
  };

  fetchPackages();
}, []);

// Обработчик выбора пакета
const handleCountryChange = (packageId: string) => {
  const selectedPkg = packages.find((pkg) => pkg.id === packageId) || null;
  setSelectedPackage(selectedPkg);

  // Устанавливаем первый доступный период как выбранный
  if (selectedPkg?.pricing.length) {
    setSelectedPeriod(selectedPkg.pricing[0]);
  } else {
    setSelectedPeriod(null);
  }
};

// Обработчик выбора периода
const handlePeriodChange = (pricingId: string) => {
  const period = selectedPackage?.pricing.find((p) => p.pricing_id === pricingId) || null;
  setSelectedPeriod(period);
};

// Обработчик заказа
const handleOrderPlan = async () => {
  if (!selectedPackage || !selectedPeriod) {
    alert(t('Please_select_a_server_and_period'));
    return;
  }

  if (!isChecked) {
    alert(t('Please_accept_the_terms_of_service'));
    return;
  }

  try {
    const token = localStorage.getItem('auth_token'); // Получаем токен из локального хранилища

    const response = await axios.post(
      'https://api.medusatest.ru:3443/api/order',
      {
        pricing_id: selectedPeriod.id,
        currency: selectedPeriod.currency,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Добавляем токен в заголовки
        },
      }
    );

    const { payment_url } = response.data;

    if (payment_url) {
      window.location.href = payment_url; // Перенаправляем пользователя на страницу оплаты
    } else {
      alert(t('Failed_to_generate_payment_link'));
    }
  } catch (error) {
    console.error('Error processing order:', error);
    alert(t('Failed_to_process_order'));
  }
};

return (
  <div className="order-container">
    <Box>
      <div className="order order-paper">
        <div className='order__header'>
          <div className='modal-paper__header'>
            <button
              onClick={toggleForm}
              className='close-btn'>
              <img src={close} alt="close" />
            </button>
          </div>
          {/* <h3>
            {t('Your_best_location_is_')}
            <span> {t('DataLine_Tier_3_')}.</span>
          </h3> */}
        </div>

        <DashboardSectionTitle text={t('Choose_server_location_')} /> 
        {packages.length > 0 ? (
          <Dropdown
            options={packages.map((pkg) => ({
              label: pkg.name,
              value: pkg.id,
            }))}
            onChange={(value) => handleCountryChange(value)}
          />
        ) : (
          <p>{t('Loading_available_servers')}</p>
        )}

        {selectedPackage && (
          <DashboardSectionTitle text={t('Choose_period_')} />            
        )}
        
        {selectedPackage && (
          <Dropdown
            options={selectedPackage.pricing.map((price) => ({
              label: `${price.term} ${price.period} - ${price.price} ${price.currency}`,
              value: price.pricing_id,
            }))}
            onChange={(value) => handlePeriodChange(value)}
          />
        )}

        {/* {selectedPeriod && (
          <div>
            <p>{t('Selected period')}: {`${selectedPeriod.term} ${selectedPeriod.period}`}</p>
            <p>{t('Price')}: {`${selectedPeriod.price} ${selectedPeriod.currency}`}</p>
          </div>
        )} */}



        {/* <div className="payment-period">
          <DashboardSectionTitle text={t('Payment_period_')} />
          <div>

            <RangeSlider />

          </div>
        </div> */}

        {/* <div className="promocode">
          <DashboardSectionTitle text={t('Have_a_promocode_')} />
          <div className="promocode-input">
            <input
              type='text'
              // value={promocode}
              // onChange={(e) => setPromocode(e.target.value)}
              placeholder={t('Enter_Promocode_')}
            />
            <Button type='button' color='blue' text={t('Apply_')} />
          </div>
        </div> */}

        <div className='order__footer'>
          <div className="order__form-agreement">
            <Checkbox
              checked={isChecked}
              onChange={setIsChecked}
            />
            <p>
              {t('Accept_')}
              <NavLink
                to="#"
              >
                {t('The_terms_of_the_offer_')}
              </NavLink>
            </p>
          </div>
          <div className="subscription">
            <div className='priceboard'>
            {selectedPeriod && (
              <div className="price-amount">
                <p className="subscription-plan">{`${selectedPeriod.term} ${selectedPeriod.period}`} {/*6 {t('Months_for_')}*/}</p>
                <span className="subscription-price">{`${selectedPeriod.price} ${selectedPeriod.currency}`}</span>
              </div>
            )}
              {/* |
              <div className="price-amount">
                <p className="subscription-plan">1 {t('Month_for_')}</p>
                <span className="subscription-price">$1,49</span>
              </div> */}
            </div>
            <Button type='button' color='purple' text={t('Order_plan_')} onClick={handleOrderPlan} />
          </div>
          
          <p className='term'>
            {/* {t('If_you_ordered_a_VPS_and_you_are_not_satisfied_with_the_quality_of_its_work_then_within_15_days_from_the_date_of_order_we_will_make_a_refund_in_any_case_')} */}
          </p>
        </div>
      </div>


    </Box>
    {/* <Box>
      <div className="order-receipt order-paper">
        <DashboardSectionTitle text={t('Your_order_')} />

        <div className="order-details">
          <div className="order-details__item">
            <span>
              {t('Virtualization_')}
            </span>
            <p>
              {t('KVM_')}
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('Bandwidth_fair_share_')}
            </span>
            <p>
              500 {t('Mbit_')}
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('CPU_cores_')}
            </span>
            <p>
              4
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('RAM_size_')}
            </span>
            <p>
              4096 Mb
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('NVMe_size_')}
            </span>
            <p>
              50 Gb
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('IPv4_addresses_1_unit_')}
            </span>
            <p>
              0 {t('Month_')}
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('IPv6_addresses_1_unit_')}
            </span>
            <p>
              $0 / {t('Month_')}
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('CentOS_7_64bit_default_')}
            </span>
            <p>
              $0
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('Location_')}
            </span>
            <p>
              {t('DataLine_Tier_3_')}
            </p>
          </div>
          <div className="order-details__item">
            <span>
              {t('NVMe_IO_')}
            </span>
            <p>
              700 {t('MB_sec_')}
            </p>
          </div>
        </div>

        <div className="subscription">
          <div className='priceboard'>
            <div className="price-amount">
              <p className="subscription-plan">6 {t('Months_for_')}</p>
              <span className="subscription-price">$89,70</span>
            </div>
            |
            <div className="price-amount">
              <p className="subscription-plan">1 {t('Month_for_')}</p>
              <span className="subscription-price">$1,49</span>
            </div>
          </div>
          <Button type='button' color='purple' text={t('Order_plan_')} />
        </div>

        <div className="order-receipt__form-agreement">
          <Checkbox
            checked={isChecked}
            onChange={setIsChecked}
          />
          <p>
            {t('Accept_')}
            <NavLink
              to="#"
            >
              {t('The_terms_of_the_offer_')}
            </NavLink>
          </p>
        </div>
      </div>
    </Box> */}
  </div>
);
}
