import React, { useState } from 'react';
import { dropdownArrow } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface CustomDropdownProps {
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
}

export const Dropdown: React.FC<CustomDropdownProps> = ({ options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
  const { t } = useTranslation('otherElements');

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    setIsOpen(false);
    onChange(value);
  };

  const handleHeaderClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className="custom-dropdown">
      <div
        className="dropdown-header"
        onClick={handleHeaderClick}
        role="button"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <p>{selectedOption
          ? options.find((option) => option.value === selectedOption)?.label
          : t('Dropdown_select_option')}</p>
        <img
          src={dropdownArrow}
          className={`dropdown-icon ${isOpen ? 'open' : ''}`}
          alt="Dropdown arrow"
        />
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option, index) => (
            <li
              key={option.value}
              className="dropdown-option"
              onClick={() => handleOptionClick(option.value)}
              role="option"
              aria-selected={selectedOption === option.value}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
