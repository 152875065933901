import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox } from 'components';

import './styles.scss';

interface SignUpProps {
  toggleForm: () => void;
}

export const SignUp: React.FC<SignUpProps> = ({ toggleForm }) => {
  const { t } = useTranslation('signUp');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isChecked) {
      alert(t('You_must_agree_to_the_terms_of_the_offer.'));
      return;
    }

    if (password !== confirmPassword) {
      alert(t('Passwords_do_not_match.'));
      return;
    }

    const payload = {
      username: email,
      new_password: password,
      confirm_password: confirmPassword,
      client_group_id: '1', // ID группы клиентов
      first_name: email, // Используем email как имя
      last_name: email, // Используем email как фамилию
      email: email,
      email_verification: '1',
    };

    try {
      // Выполняем основной POST-запрос
      const response = await fetch('https://api.medusatest.ru:3443/api/clients/create.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }

      const data = await response.json();
      alert(t('Registration_successful!'));
      console.log(data);
    } catch (error) {
      console.error('Error during registration:', error);
      alert(t('Registration_failed!'));
    }
  };

  return (
    <div id="modal">
      <div className="modal__container">
        <form className="modal__form" onSubmit={handleSubmit} method="post" name="sign_form">
          <div className="modal__form-header">
            <button className="modal__form-close" type="button"></button>
            <h2>
              {t('Sign_up_')}
            </h2>
          </div>

          <div className="modal__form-content">
            <div className="modal__form-item">
              <p>{t('Email_')}</p>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="modal__form-item">
              <p>{t('Password_')}</p>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="modal__form-item">
              <p>{t('Confirm_password_')}</p>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="modal__form-agreement">
              <Checkbox
                checked={isChecked}
                onChange={setIsChecked}
              />
              <p>
                {t('Agree_with_the_terms_of_')}
                <NavLink
                  className="modal__form-footer-link"
                  to="#"
                >
                  {t('the_offer_')}
                </NavLink>
              </p>
            </div>
          </div>

          <div className="modal__form-footer">
            <p>
              {t('Already_have_')}
              <br />
              {t('An_account_')}
              <NavLink
                className="modal__form-footer-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleForm();
                }}>
                {t('Sign_in_')}
              </NavLink>
            </p>
            <Button type="submit" color="purple" text={t('Sign_up_')} />
          </div>
        </form>
      </div>
    </div>
  );
};
