import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/authContext';
import { ModalContext } from 'context/ModalContext';

import { Button } from 'components';

import './styles.scss';

interface SignInProps {
  toggleForm: () => void;
}

export const SignIn: React.FC<SignInProps> = ({ toggleForm }) => {
  const { t } = useTranslation('signIn');
  const { setIsAuthenticated } = useContext(AuthContext);
  const { setOpenModal } = useContext(ModalContext); // Добавляем доступ к управлению модальным окном
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loading) return;

    try {
      setLoading(true);

      // Отправляем запрос на авторизацию
      const response = await fetch('https://api.medusatest.ru:3443/api/sign-in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Authorization failed');
      }

      const data = await response.json();
      console.log('Authorization successful:', data);

      // Сохраняем токен и данные пользователя
      localStorage.setItem('auth_token', data.token);
      localStorage.setItem('user_data', JSON.stringify(data.userData));

      // Обновляем контекст и закрываем модальное окно
      setIsAuthenticated(true);
      setOpenModal(null); // Закрытие модального окна

      // Перенаправляем на личный кабинет
      navigate('/account-page');
    } catch (error) {
      console.error('Error during authorization:', error);
      alert(t('Authorization_failed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="modal">
      <div className="modal__container">
        <form className="modal__form" onSubmit={handleSubmit} method="post" name="sign_form">
          <div className="modal__form-header">
            <button className="modal__form-close" type="button"></button>
            <h2>{t('Sign_in_')}</h2>
          </div>

          <div className="modal__form-content">
            <div className="modal__form-item">
              <p>{t('Login_')}</p>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="modal__form-item">
              <p>{t('Password_')}</p>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <NavLink to="link">{t('Forgot_your_password_')}</NavLink>
          </div>

          <div className="modal__form-footer">
            <p>
              {t('New_member_')}
              <NavLink
                className="modal__form-footer-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleForm();
                }}
              >
                {t('Sign_up_')}
              </NavLink>
            </p>
            <Button type="submit" color="purple" text={loading ? t('Loading_') : t('Sign_in_')} />
          </div>
        </form>
      </div>
    </div>
  );
};
