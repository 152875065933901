import React from "react";
import { Outlet } from "react-router-dom";

import './styles.scss'

interface DashboardContainerProps {
  userData: any; // Укажите конкретный тип, если известно
}

export const DashboardContainer: React.FC<DashboardContainerProps> = ({ userData }) => {

  return (
    <div className="dashboard">
      <Outlet />
    </div>
  )
}